import React from 'react'
import ConsultingTemplate from 'components/templates/ConsultingTemplate/ConsultingTemplate'

export default function ConsultingTendersPage() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const productName = 'Gare D’Appalto'

  const description =
    'Oggi la maggior parte delle aziende realizzano un progetto seriale, standard e spesso ripetitivo; ING GROUP, invece, redige progetti studiati a hoc per la singola gara cucendoli addosso alle esigenze e alle potenzialità di ogni singola azienda. Ovviamente ogni progetto è unico e lavoriamo in esclusiva per un’unica impresa richiedente con un solo obiettivo: vincere la gara. Ad oggi vantiamo una percentuale di gare vinte di circa il 60%.<br><br> <b>Il nostro servizio prevede le seguenti prestazioni:</b> <br><br>– Disponibilità di un’equipe di progetto dedicata in esclusiva al cliente; <br>– Studio del bando, disciplinare ed allegati con attenzione alla tabella dei punteggi e dei vari sub-elementi; <br>– Eventuale sopralluogo per conto del cliente; <br> – Interfaccia continuativa con il responsabile indicato dal cliente; <br>– Redazione del progetto di gara inclusivo di tutti gli allegati richiesti; <br>– Stampa di tutta la documentazione preparata; <br>– Controllo qualità di tutta la documentazione stampata; <br>– Consegna del progetto di gara al cliente; <br><br>Obiettivo unico: VINCERE LA GARA!'

  return (
    <>
      <ConsultingTemplate seoKeywords={seoKeywords} productName={productName} description={description} />
    </>
  )
}
